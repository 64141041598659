// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-affiliate-program-jsx": () => import("./../../../src/pages/affiliate-program.jsx" /* webpackChunkName: "component---src-pages-affiliate-program-jsx" */),
  "component---src-pages-agency-backup-solution-jsx": () => import("./../../../src/pages/agency-backup-solution.jsx" /* webpackChunkName: "component---src-pages-agency-backup-solution-jsx" */),
  "component---src-pages-alternative-to-backupsheep-jsx": () => import("./../../../src/pages/alternative-to-backupsheep.jsx" /* webpackChunkName: "component---src-pages-alternative-to-backupsheep-jsx" */),
  "component---src-pages-baobab-jsx": () => import("./../../../src/pages/baobab.jsx" /* webpackChunkName: "component---src-pages-baobab-jsx" */),
  "component---src-pages-case-study-jsx": () => import("./../../../src/pages/case-study.jsx" /* webpackChunkName: "component---src-pages-case-study-jsx" */),
  "component---src-pages-catalog-jsx": () => import("./../../../src/pages/catalog.jsx" /* webpackChunkName: "component---src-pages-catalog-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-convince-my-boss-jsx": () => import("./../../../src/pages/convince-my-boss.jsx" /* webpackChunkName: "component---src-pages-convince-my-boss-jsx" */),
  "component---src-pages-cookie-policy-jsx": () => import("./../../../src/pages/cookie-policy.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-jsx" */),
  "component---src-pages-database-aws-rds-backup-jsx": () => import("./../../../src/pages/database/aws-rds-backup.jsx" /* webpackChunkName: "component---src-pages-database-aws-rds-backup-jsx" */),
  "component---src-pages-database-dbaas-backup-jsx": () => import("./../../../src/pages/database/dbaas-backup.jsx" /* webpackChunkName: "component---src-pages-database-dbaas-backup-jsx" */),
  "component---src-pages-database-neon-jsx": () => import("./../../../src/pages/database/neon.jsx" /* webpackChunkName: "component---src-pages-database-neon-jsx" */),
  "component---src-pages-database-planetscale-backup-jsx": () => import("./../../../src/pages/database/planetscale-backup.jsx" /* webpackChunkName: "component---src-pages-database-planetscale-backup-jsx" */),
  "component---src-pages-database-supabase-jsx": () => import("./../../../src/pages/database/supabase.jsx" /* webpackChunkName: "component---src-pages-database-supabase-jsx" */),
  "component---src-pages-digitalocean-backup-jsx": () => import("./../../../src/pages/digitalocean-backup.jsx" /* webpackChunkName: "component---src-pages-digitalocean-backup-jsx" */),
  "component---src-pages-eula-jsx": () => import("./../../../src/pages/eula.jsx" /* webpackChunkName: "component---src-pages-eula-jsx" */),
  "component---src-pages-features-api-and-backup-automation-jsx": () => import("./../../../src/pages/features/api-and-backup-automation.jsx" /* webpackChunkName: "component---src-pages-features-api-and-backup-automation-jsx" */),
  "component---src-pages-features-backup-notifications-jsx": () => import("./../../../src/pages/features/backup-notifications.jsx" /* webpackChunkName: "component---src-pages-features-backup-notifications-jsx" */),
  "component---src-pages-features-backup-schedule-and-retention-jsx": () => import("./../../../src/pages/features/backup-schedule-and-retention.jsx" /* webpackChunkName: "component---src-pages-features-backup-schedule-and-retention-jsx" */),
  "component---src-pages-features-cloudstorage-backup-jsx": () => import("./../../../src/pages/features/cloudstorage-backup.jsx" /* webpackChunkName: "component---src-pages-features-cloudstorage-backup-jsx" */),
  "component---src-pages-features-find-and-restore-backup-jsx": () => import("./../../../src/pages/features/find-and-restore-backup.jsx" /* webpackChunkName: "component---src-pages-features-find-and-restore-backup-jsx" */),
  "component---src-pages-features-incremental-backup-jsx": () => import("./../../../src/pages/features/incremental-backup.jsx" /* webpackChunkName: "component---src-pages-features-incremental-backup-jsx" */),
  "component---src-pages-features-index-jsx": () => import("./../../../src/pages/features/index.jsx" /* webpackChunkName: "component---src-pages-features-index-jsx" */),
  "component---src-pages-features-teams-and-projects-jsx": () => import("./../../../src/pages/features/teams-and-projects.jsx" /* webpackChunkName: "component---src-pages-features-teams-and-projects-jsx" */),
  "component---src-pages-gdpr-sheet-jsx": () => import("./../../../src/pages/gdpr-sheet.jsx" /* webpackChunkName: "component---src-pages-gdpr-sheet-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-integrations-aws-s-3-glacier-backup-jsx": () => import("./../../../src/pages/integrations/aws-s3-glacier-backup.jsx" /* webpackChunkName: "component---src-pages-integrations-aws-s-3-glacier-backup-jsx" */),
  "component---src-pages-integrations-runcloud-backup-jsx": () => import("./../../../src/pages/integrations/runcloud-backup.jsx" /* webpackChunkName: "component---src-pages-integrations-runcloud-backup-jsx" */),
  "component---src-pages-mongodb-backup-jsx": () => import("./../../../src/pages/mongodb-backup.jsx" /* webpackChunkName: "component---src-pages-mongodb-backup-jsx" */),
  "component---src-pages-mysql-backup-jsx": () => import("./../../../src/pages/mysql-backup.jsx" /* webpackChunkName: "component---src-pages-mysql-backup-jsx" */),
  "component---src-pages-partnerships-jsx": () => import("./../../../src/pages/partnerships.jsx" /* webpackChunkName: "component---src-pages-partnerships-jsx" */),
  "component---src-pages-postgresql-backup-jsx": () => import("./../../../src/pages/postgresql-backup.jsx" /* webpackChunkName: "component---src-pages-postgresql-backup-jsx" */),
  "component---src-pages-pricing-calculator-jsx": () => import("./../../../src/pages/pricing/calculator.jsx" /* webpackChunkName: "component---src-pages-pricing-calculator-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-redis-backup-jsx": () => import("./../../../src/pages/redis-backup.jsx" /* webpackChunkName: "component---src-pages-redis-backup-jsx" */),
  "component---src-pages-saas-backup-gitea-jsx": () => import("./../../../src/pages/saas-backup/gitea.jsx" /* webpackChunkName: "component---src-pages-saas-backup-gitea-jsx" */),
  "component---src-pages-saas-backup-github-jsx": () => import("./../../../src/pages/saas-backup/github.jsx" /* webpackChunkName: "component---src-pages-saas-backup-github-jsx" */),
  "component---src-pages-saas-backup-gitlab-jsx": () => import("./../../../src/pages/saas-backup/gitlab.jsx" /* webpackChunkName: "component---src-pages-saas-backup-gitlab-jsx" */),
  "component---src-pages-saas-backup-index-jsx": () => import("./../../../src/pages/saas-backup/index.jsx" /* webpackChunkName: "component---src-pages-saas-backup-index-jsx" */),
  "component---src-pages-saas-backup-knack-jsx": () => import("./../../../src/pages/saas-backup/knack.jsx" /* webpackChunkName: "component---src-pages-saas-backup-knack-jsx" */),
  "component---src-pages-saas-backup-notion-jsx": () => import("./../../../src/pages/saas-backup/notion.jsx" /* webpackChunkName: "component---src-pages-saas-backup-notion-jsx" */),
  "component---src-pages-saas-backup-solution-jsx": () => import("./../../../src/pages/saas-backup-solution.jsx" /* webpackChunkName: "component---src-pages-saas-backup-solution-jsx" */),
  "component---src-pages-security-first-jsx": () => import("./../../../src/pages/security-first.jsx" /* webpackChunkName: "component---src-pages-security-first-jsx" */),
  "component---src-pages-server-backup-index-jsx": () => import("./../../../src/pages/server-backup/index.jsx" /* webpackChunkName: "component---src-pages-server-backup-index-jsx" */),
  "component---src-pages-snapshots-amazon-lightsail-jsx": () => import("./../../../src/pages/snapshots/amazon-lightsail.jsx" /* webpackChunkName: "component---src-pages-snapshots-amazon-lightsail-jsx" */),
  "component---src-pages-snapshots-aws-ec-2-jsx": () => import("./../../../src/pages/snapshots/aws-ec2.jsx" /* webpackChunkName: "component---src-pages-snapshots-aws-ec-2-jsx" */),
  "component---src-pages-snapshots-digitalocean-jsx": () => import("./../../../src/pages/snapshots/digitalocean.jsx" /* webpackChunkName: "component---src-pages-snapshots-digitalocean-jsx" */),
  "component---src-pages-snapshots-exoscale-jsx": () => import("./../../../src/pages/snapshots/exoscale.jsx" /* webpackChunkName: "component---src-pages-snapshots-exoscale-jsx" */),
  "component---src-pages-snapshots-hetzner-jsx": () => import("./../../../src/pages/snapshots/hetzner.jsx" /* webpackChunkName: "component---src-pages-snapshots-hetzner-jsx" */),
  "component---src-pages-snapshots-index-jsx": () => import("./../../../src/pages/snapshots/index.jsx" /* webpackChunkName: "component---src-pages-snapshots-index-jsx" */),
  "component---src-pages-snapshots-linode-akamai-jsx": () => import("./../../../src/pages/snapshots/linode-akamai.jsx" /* webpackChunkName: "component---src-pages-snapshots-linode-akamai-jsx" */),
  "component---src-pages-snapshots-outscale-jsx": () => import("./../../../src/pages/snapshots/outscale.jsx" /* webpackChunkName: "component---src-pages-snapshots-outscale-jsx" */),
  "component---src-pages-snapshots-ovh-jsx": () => import("./../../../src/pages/snapshots/ovh.jsx" /* webpackChunkName: "component---src-pages-snapshots-ovh-jsx" */),
  "component---src-pages-snapshots-scaleway-jsx": () => import("./../../../src/pages/snapshots/scaleway.jsx" /* webpackChunkName: "component---src-pages-snapshots-scaleway-jsx" */),
  "component---src-pages-snapshots-upcloud-jsx": () => import("./../../../src/pages/snapshots/upcloud.jsx" /* webpackChunkName: "component---src-pages-snapshots-upcloud-jsx" */),
  "component---src-pages-snapshots-vultr-jsx": () => import("./../../../src/pages/snapshots/vultr.jsx" /* webpackChunkName: "component---src-pages-snapshots-vultr-jsx" */),
  "component---src-pages-storage-alibaba-jsx": () => import("./../../../src/pages/storage/alibaba.jsx" /* webpackChunkName: "component---src-pages-storage-alibaba-jsx" */),
  "component---src-pages-storage-aws-glacier-jsx": () => import("./../../../src/pages/storage/aws-glacier.jsx" /* webpackChunkName: "component---src-pages-storage-aws-glacier-jsx" */),
  "component---src-pages-storage-aws-s-3-jsx": () => import("./../../../src/pages/storage/aws-s3.jsx" /* webpackChunkName: "component---src-pages-storage-aws-s-3-jsx" */),
  "component---src-pages-storage-azure-jsx": () => import("./../../../src/pages/storage/azure.jsx" /* webpackChunkName: "component---src-pages-storage-azure-jsx" */),
  "component---src-pages-storage-backblaze-jsx": () => import("./../../../src/pages/storage/backblaze.jsx" /* webpackChunkName: "component---src-pages-storage-backblaze-jsx" */),
  "component---src-pages-storage-backup-alibaba-jsx": () => import("./../../../src/pages/storage-backup/alibaba.jsx" /* webpackChunkName: "component---src-pages-storage-backup-alibaba-jsx" */),
  "component---src-pages-storage-backup-aws-s-3-jsx": () => import("./../../../src/pages/storage-backup/aws-s3.jsx" /* webpackChunkName: "component---src-pages-storage-backup-aws-s-3-jsx" */),
  "component---src-pages-storage-backup-azure-jsx": () => import("./../../../src/pages/storage-backup/azure.jsx" /* webpackChunkName: "component---src-pages-storage-backup-azure-jsx" */),
  "component---src-pages-storage-backup-backblaze-jsx": () => import("./../../../src/pages/storage-backup/backblaze.jsx" /* webpackChunkName: "component---src-pages-storage-backup-backblaze-jsx" */),
  "component---src-pages-storage-backup-cloudflare-r-2-jsx": () => import("./../../../src/pages/storage-backup/cloudflare-r2.jsx" /* webpackChunkName: "component---src-pages-storage-backup-cloudflare-r-2-jsx" */),
  "component---src-pages-storage-backup-digitalocean-jsx": () => import("./../../../src/pages/storage-backup/digitalocean.jsx" /* webpackChunkName: "component---src-pages-storage-backup-digitalocean-jsx" */),
  "component---src-pages-storage-backup-dropbox-jsx": () => import("./../../../src/pages/storage-backup/dropbox.jsx" /* webpackChunkName: "component---src-pages-storage-backup-dropbox-jsx" */),
  "component---src-pages-storage-backup-exoscale-jsx": () => import("./../../../src/pages/storage-backup/exoscale.jsx" /* webpackChunkName: "component---src-pages-storage-backup-exoscale-jsx" */),
  "component---src-pages-storage-backup-filebase-jsx": () => import("./../../../src/pages/storage-backup/filebase.jsx" /* webpackChunkName: "component---src-pages-storage-backup-filebase-jsx" */),
  "component---src-pages-storage-backup-google-cloud-storage-jsx": () => import("./../../../src/pages/storage-backup/google-cloud-storage.jsx" /* webpackChunkName: "component---src-pages-storage-backup-google-cloud-storage-jsx" */),
  "component---src-pages-storage-backup-google-drive-jsx": () => import("./../../../src/pages/storage-backup/google-drive.jsx" /* webpackChunkName: "component---src-pages-storage-backup-google-drive-jsx" */),
  "component---src-pages-storage-backup-idrive-e-2-jsx": () => import("./../../../src/pages/storage-backup/idrive-e2.jsx" /* webpackChunkName: "component---src-pages-storage-backup-idrive-e-2-jsx" */),
  "component---src-pages-storage-backup-index-jsx": () => import("./../../../src/pages/storage-backup/index.jsx" /* webpackChunkName: "component---src-pages-storage-backup-index-jsx" */),
  "component---src-pages-storage-backup-leviia-jsx": () => import("./../../../src/pages/storage-backup/leviia.jsx" /* webpackChunkName: "component---src-pages-storage-backup-leviia-jsx" */),
  "component---src-pages-storage-backup-minio-jsx": () => import("./../../../src/pages/storage-backup/minio.jsx" /* webpackChunkName: "component---src-pages-storage-backup-minio-jsx" */),
  "component---src-pages-storage-backup-outscale-jsx": () => import("./../../../src/pages/storage-backup/outscale.jsx" /* webpackChunkName: "component---src-pages-storage-backup-outscale-jsx" */),
  "component---src-pages-storage-backup-sftp-jsx": () => import("./../../../src/pages/storage-backup/sftp.jsx" /* webpackChunkName: "component---src-pages-storage-backup-sftp-jsx" */),
  "component---src-pages-storage-backup-storadera-jsx": () => import("./../../../src/pages/storage-backup/storadera.jsx" /* webpackChunkName: "component---src-pages-storage-backup-storadera-jsx" */),
  "component---src-pages-storage-backup-storj-jsx": () => import("./../../../src/pages/storage-backup/storj.jsx" /* webpackChunkName: "component---src-pages-storage-backup-storj-jsx" */),
  "component---src-pages-storage-backup-supabase-jsx": () => import("./../../../src/pages/storage-backup/supabase.jsx" /* webpackChunkName: "component---src-pages-storage-backup-supabase-jsx" */),
  "component---src-pages-storage-backup-synology-jsx": () => import("./../../../src/pages/storage-backup/synology.jsx" /* webpackChunkName: "component---src-pages-storage-backup-synology-jsx" */),
  "component---src-pages-storage-backup-upcloud-jsx": () => import("./../../../src/pages/storage-backup/upcloud.jsx" /* webpackChunkName: "component---src-pages-storage-backup-upcloud-jsx" */),
  "component---src-pages-storage-backup-wasabi-jsx": () => import("./../../../src/pages/storage-backup/wasabi.jsx" /* webpackChunkName: "component---src-pages-storage-backup-wasabi-jsx" */),
  "component---src-pages-storage-cloudflare-r-2-jsx": () => import("./../../../src/pages/storage/cloudflare-r2.jsx" /* webpackChunkName: "component---src-pages-storage-cloudflare-r-2-jsx" */),
  "component---src-pages-storage-digitalocean-jsx": () => import("./../../../src/pages/storage/digitalocean.jsx" /* webpackChunkName: "component---src-pages-storage-digitalocean-jsx" */),
  "component---src-pages-storage-dropbox-jsx": () => import("./../../../src/pages/storage/dropbox.jsx" /* webpackChunkName: "component---src-pages-storage-dropbox-jsx" */),
  "component---src-pages-storage-exoscale-jsx": () => import("./../../../src/pages/storage/exoscale.jsx" /* webpackChunkName: "component---src-pages-storage-exoscale-jsx" */),
  "component---src-pages-storage-filebase-jsx": () => import("./../../../src/pages/storage/filebase.jsx" /* webpackChunkName: "component---src-pages-storage-filebase-jsx" */),
  "component---src-pages-storage-google-cloud-storage-jsx": () => import("./../../../src/pages/storage/google-cloud-storage.jsx" /* webpackChunkName: "component---src-pages-storage-google-cloud-storage-jsx" */),
  "component---src-pages-storage-google-drive-jsx": () => import("./../../../src/pages/storage/google-drive.jsx" /* webpackChunkName: "component---src-pages-storage-google-drive-jsx" */),
  "component---src-pages-storage-idrive-e-2-jsx": () => import("./../../../src/pages/storage/idrive-e2.jsx" /* webpackChunkName: "component---src-pages-storage-idrive-e-2-jsx" */),
  "component---src-pages-storage-leviia-jsx": () => import("./../../../src/pages/storage/leviia.jsx" /* webpackChunkName: "component---src-pages-storage-leviia-jsx" */),
  "component---src-pages-storage-minio-jsx": () => import("./../../../src/pages/storage/minio.jsx" /* webpackChunkName: "component---src-pages-storage-minio-jsx" */),
  "component---src-pages-storage-outscale-jsx": () => import("./../../../src/pages/storage/outscale.jsx" /* webpackChunkName: "component---src-pages-storage-outscale-jsx" */),
  "component---src-pages-storage-sftp-jsx": () => import("./../../../src/pages/storage/sftp.jsx" /* webpackChunkName: "component---src-pages-storage-sftp-jsx" */),
  "component---src-pages-storage-simplestorage-jsx": () => import("./../../../src/pages/storage/simplestorage.jsx" /* webpackChunkName: "component---src-pages-storage-simplestorage-jsx" */),
  "component---src-pages-storage-storadera-jsx": () => import("./../../../src/pages/storage/storadera.jsx" /* webpackChunkName: "component---src-pages-storage-storadera-jsx" */),
  "component---src-pages-storage-storj-jsx": () => import("./../../../src/pages/storage/storj.jsx" /* webpackChunkName: "component---src-pages-storage-storj-jsx" */),
  "component---src-pages-storage-supabase-jsx": () => import("./../../../src/pages/storage/supabase.jsx" /* webpackChunkName: "component---src-pages-storage-supabase-jsx" */),
  "component---src-pages-storage-synology-jsx": () => import("./../../../src/pages/storage/synology.jsx" /* webpackChunkName: "component---src-pages-storage-synology-jsx" */),
  "component---src-pages-storage-upcloud-jsx": () => import("./../../../src/pages/storage/upcloud.jsx" /* webpackChunkName: "component---src-pages-storage-upcloud-jsx" */),
  "component---src-pages-storage-wasabi-jsx": () => import("./../../../src/pages/storage/wasabi.jsx" /* webpackChunkName: "component---src-pages-storage-wasabi-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */),
  "component---src-templates-blog-list-jsx": () => import("./../../../src/templates/blog-list.jsx" /* webpackChunkName: "component---src-templates-blog-list-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-blog-tag-jsx": () => import("./../../../src/templates/blog-tag.jsx" /* webpackChunkName: "component---src-templates-blog-tag-jsx" */),
  "component---src-templates-casestudy-post-jsx": () => import("./../../../src/templates/casestudy-post.jsx" /* webpackChunkName: "component---src-templates-casestudy-post-jsx" */),
  "component---src-templates-regions-jsx": () => import("./../../../src/templates/regions.jsx" /* webpackChunkName: "component---src-templates-regions-jsx" */)
}

